import React from "react";
import { Provider } from 'react-redux';
/*
import createStore from './createStore';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()
  return <Provider store={store}>{element}</Provider>
}

*/

import { createStore, combineReducers } from 'redux';
import { storeManager } from '@data-provider/core';

import './config';

const DATA_PROVIDER_STORE = 'data';

const store = createStore(
    combineReducers({
      [DATA_PROVIDER_STORE]: storeManager.reducer
    })
);

storeManager.setStore(store, DATA_PROVIDER_STORE);

export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    return <Provider store={store}>{element}</Provider>
  }