// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-post-index-tsx": () => import("./../../../src/components/Post/index.tsx" /* webpackChunkName: "component---src-components-post-index-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-charity-tsx": () => import("./../../../src/pages/charity.tsx" /* webpackChunkName: "component---src-pages-charity-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-business-business-bytag-tsx": () => import("./../../../src/templates/business/business-bytag.tsx" /* webpackChunkName: "component---src-templates-business-business-bytag-tsx" */),
  "component---src-templates-business-business-detail-tsx": () => import("./../../../src/templates/business/business-detail.tsx" /* webpackChunkName: "component---src-templates-business-business-detail-tsx" */),
  "component---src-templates-business-business-list-tsx": () => import("./../../../src/templates/business/business-list.tsx" /* webpackChunkName: "component---src-templates-business-business-list-tsx" */),
  "component---src-templates-business-business-map-tsx": () => import("./../../../src/templates/business/business-map.tsx" /* webpackChunkName: "component---src-templates-business-business-map-tsx" */),
  "component---src-templates-menu-card-menu-card-tsx": () => import("./../../../src/templates/menu-card/menu-card.tsx" /* webpackChunkName: "component---src-templates-menu-card-menu-card-tsx" */),
  "component---src-templates-promo-promo-list-tsx": () => import("./../../../src/templates/promo/promo-list.tsx" /* webpackChunkName: "component---src-templates-promo-promo-list-tsx" */)
}

